import React from "react";
import Accordion from "./Accordion";

import acc1 from "../Images/acerdeonImj/line_1.jpg";
import acc2 from "../Images/acerdeonImj/line_2.jpg";

import acc3_1 from "../Images/acerdeonImj/line_3_1.jpg";
import acc3_2 from "../Images/acerdeonImj/line_3_2.jpg";
import acc3_3 from "../Images/acerdeonImj/line_3_3.jpg";
import acc3_4 from "../Images/acerdeonImj/line_3_4.jpg";
import acc3_5 from "../Images/acerdeonImj/line_3_5.webp";
import acc3_6 from "../Images/acerdeonImj/line_3_6.jpg";

import acc4 from "../Images/acerdeonImj/line_4.jpg";
import acc5 from "../Images/acerdeonImj/line_5.jpg";
import acc6 from "../Images/acerdeonImj/line_6.jpg";
import acc7 from "../Images/acerdeonImj/line_7.webp";
import acc8 from "../Images/acerdeonImj/line_8.jpg";
import acc9 from "../Images/acerdeonImj/line_9.png";

import acc10_1 from "../Images/acerdeonImj/line_10_1.jpg";
import acc10_2 from "../Images/acerdeonImj/line_10_2.webp";
import acc10_3 from "../Images/acerdeonImj/line_10_3.jpg";
import acc10_4 from "../Images/acerdeonImj/line_10_4.jpg";
import acc10_5 from "../Images/acerdeonImj/line_10_5.jpg";
import { title } from "process";

export const Third_Screen = () => {
  return (
    <div className="third_Screen" id="third">
      <div className="slanted-section">
        <div className="content">
          <h2>
            У НАС ВЫ МОЖЕТЕ{" "}
            <span className="grey"> ПРИОБРЕСТИ И ВЗЯТЬ В АРЕНДУ:</span>
          </h2>
        </div>
      </div>
      <Accordion
        title="КРУПНОЩИТОВАЯ ОПАЛУБКА"
        content="Крупнощитовая опалубка – это вспомогательная конструкция, составленная из габаритных щитов. Преимуществом подобных систем является минимальное количество стыков, позволяющее качественно бетонировать масштабные объекты. Монтаж и демонтаж требуют привлечения тяжелой спецтехники, но в остальном эти процессы не отличаются особой трудоемкостью и отнимают минимум времени.

Крупнощитовая Опалубка - это вспомогательная конструкция из металла и ламинированного МДФ, служащая для придания монолитным конструкциям из бетона, железобетона, грунтовой смеси.

Опалубка крупнощитовая – это сборная конструкция, состоящая из щитов больших размеров, крепежных и опорных деталей. Используют оборудование для возведения массивных и высоких бетонных конструкций. Правильное применение крупнощитовых комплектов позволяет снизить трудозатраты и сократить время на строительство.

Опалубка подходит для гражданского строительства и возведения объектов инженерной инфраструктуры. Данная опалубка может применяться как в новом строительстве, так и в уже возведенных объектах. Отверстия для стяжей внутри щита позволяют быстро установить опалубку для заливки фундаментов, парапетов, опорных стен, колонн и балок.

По сути, эта конструкция является сборной. Основа комплекта – щит, укрепленный вертикальными и горизонтальными ребрами жесткости.

РАЗМЕРЫ ОПАЛУБОК

Высота - 3.3м

Размеры: 0.20м х 3м, высота L, до 1.20м х 3м L."
        right
        image={acc1}
      />
      <Accordion 
      title="ОПАЛУБОЧНЫЕ УГЛЫ"
      content="Угол опалубки является элементом крупнощитовой опалубки, служащим для формирования внутреннего угла замкнутого контура опалубки лифтовой шахты и бетонирования угловых сопряженных стен.

Использование распалубочного угла опалубки позволяет производить монтаж/демонтаж внутреннего ядра лифтовой шахты без разбора контура опалубки и обеспечивает перемещение системы подъемным устройством, как единого целого, в один прием."
      right={false}
      image={acc10_5}
      />
      <Accordion
        title="МЕЛКОЩИТОВАЯ ОПАЛУБКА"
        content="


Мелкощитовая опалубка - конструкция из металла и ламинированного МДФ. Это система щитов и комплектующих для бетонирования фундаментов, стен, колонн и других архитектурных форм, профессиональный инструмент, заточенный для решения задач, с которыми не справятся другие виды опалубки. Основное преимущество этой опалубки – небольшие размеры и вес элементов (до 50кг). Такой вес позволяет вести работы вручную, без крана – это, экономит бюджет строительства, а небольшой размер щитов позволяет обойти все узкие места.
"
        right={false}
        image={acc2}
      />
      <Accordion title="КОМПЛЕКТУЮЩИЕ ДЛЯ ОПАЛУБОК">
        {acc3.map((accrdn: any, index: number) => (
          <Accordion
            key={index}
            title={accrdn.title}
            content={accrdn.content}
            right={accrdn.right}
            image={accrdn.image}
          />
        ))}
      </Accordion>
      {data.map((accrdn: any, index: number) => (
        <Accordion
          key={index}
          title={accrdn.title}
          content={accrdn.content}
          right={accrdn.right}
          image={accrdn.image}
        />
      ))}
      <Accordion title="МЕТАЛЛОПРОКАТ">
        {acc10.map((accrdn: any, index: number) => (
          <Accordion
            key={index}
            title={accrdn.title}
            content={accrdn.content}
            right={accrdn.right}
            image={accrdn.image}
          />
        ))}
      </Accordion>
    </div>
  );
};

export default Third_Screen;

const data = [
  {
    title: "КОЛОННЫ ОПАЛУБОЧНЫЕ",
    content: `Колонна — это столб, который состоит в опорной части сооружения или кровли. Они появились ещё в античные времена и актуальны и по сей день. Причем назначение колонн достаточно разнообразно. К основным функциям можно отнести опорную и декоративную.

По своей форме они бывают разных видов: цилиндрические, квадратные, прямоугольные и многогранные. От выбора формы зависит не только внешний вид, но и надёжность, прочность всей конструкции. Толщина и высота колонны зависит от архитектуры строящегося здания.

В зависимости от вида колонны к ней формируется опалубка, которая в дальнейшем будет использоваться как форма для их создания. Опалубка имеет большое значение для создания надёжной опоры, поэтому нужно тщательно подходить к её формированию. В основном применяют съёмные и несъёмные её варианты, которые отличаются друг от друга скоростью монтажа.`,
    right: true,
    image: acc4,
  },
  {
    title: "СТРУБЦИНА",
    content: `Простое изобретение – стойка для опалубки – позволяет устраивать монолитные перекрытия разной конфигурации (прямые, наклонные, с капителями) для любых зданий с перекрытиями толщиной до 400 мм и высотой до 5,0м: от частного дома, возводимого своими руками, до производственного помещения.

Стойка телескопическая – простое в использовании и надёжное приспособление, служащее опорой для опалубки перекрытий. Благодаря небольшому весу (не более 20 кг) установка телескопических опор легко выполняется даже в одиночку. Стойка под опалубку состоит из двух стальных или алюминиевых труб разных диаметров.

К трубе большего диаметра снизу приварена опорная площадка, а сверху – насадка с накатанной на неё резьбой и прорезями для крепежа. Размеры стоек телескопических для опалубки стандартные, длиной от 3,1 до 5,0 м. Нагрузка, которую они способны выдерживать, колеблется в диапазоне от 1 до 4 тонн.

Для защиты от коррозии опоры под опалубку окрашиваются нитроэмалью или порошковой краской, оцинковываются отдельные детали (насадки с резьбой, опорные гайки, крепёжные серьги).`,
    right: false,
    image: acc5,
  },
  {
    title: "ДВУТАВРОВАЯ БАЛКА",
    content: `Деревянные двутавровые балки позволяют формировать конструкцию высокой прочности за счет полок и стенок, которые изготавливаются из клееного бруса. Так как балкам приходится часто контактировать с бетонным раствором, а также подвергаться воздействию осадков, солнечных лучей, они покрыты защитными составами, предотвращающими гниение и защищающими дерево от паразитов. Торцы, которые представляют собой наиболее уязвимое место, также подлежат усилению и покрыты металлическими либо пластиковыми колпаками. Конструктивно деревянный двутавр (в отличие от стального) состоит из верхней и нижней полок, которые изготавливаются из деревянного бруса, и стенки.

Балочные опалубки перекрытий удобны в монтаже, кроме того, они позволяют получить идеально ровную бетонную поверхность.
`,
    right: true,
    image: acc6,
  },
  {
    title: "ЛЕСА МОНОЛИТНЫЕ",
    content: `Леса монолитные, в комплекте

1,5м высота

2м высота

в комплект входит:

Рама - 2шт

Диагональ - 2шт

резьба нижний

резьба верхний

Леса фасадная

Рама - 2шт

Диагональ - 3шт

трап металлический

`,
    right: false,
    image: acc7,
  },
  {
    title: "ЛЕСА ФАСАДНЫЕ",
    content: `Для выполнения ремонтных и реставрационных работ на фасаде зданий используют строительные леса. Они создают удобные и безопасные условия для работников при отделке наружных стен на высоте. Фасадные строительные леса практически незаменимы при облицовке, утеплении, гидроизоляции, создании вентилируемых систем и т.д. Благодаря сборной конструкции, они позволяют не только свободно перемещаться вдоль рабочей области, но и хранить в зоне доступа необходимый строительный материал и инструмент. А использование прочных элементов и различных крепежных решений, обеспечивает им стойкость к деформационным нагрузкам и универсальность использования.`,
    right: true,
    image: acc8,
  },
  {
    title: "ФАНЕРА ЛАМИНИРОВАННАЯ",
    content: `
Для сооружения опалубки под фундамент могут практиковаться самые разные материалы, но особенно востребована ламинированная фанера. Она являет собой строительный лист, покрытый фенолформальдегидной пленкой. Пленка, нанесенная на фанеру, делает ее влагостойкой, устойчивой к перепадам окружающей температуры, износоустойчивой.

Фанера высокого качества получается посредством прессовки нескольких (от 3 до 10) тонких листов древесины (шпона). Поперечное расположение волокон в листах дает возможность делать фанеру очень прочным материалом.

Положительными факторами использования фанеры облицованной плёнкой являются:

влагостойкость;

высокая устойчивость к изгибам либо растяжениям;

возможность многоразового использования без потери начальных характеристик;

большие размеры целостных листов;

высокая износоустойчивость.
`,
    right: false,
    image: acc9,
  },
];

const acc3 = [
  {
    title: "ТАЙРОТЫ",
    content: `Тайрот – это стяжной болт. Он используется для стяжки щитов, при заливки бетона. Стяжка работает в паре с барашковыми гайками и непосредственно воспринимает давление бетонной смеси. Изготовлен из алюминиевого состава сплава для прочности и легкости соединения конструкции.`,
    right: true,
    image: acc3_1,
  },
  {
    title: "КЛИНОВЫЕ ЗАМКИ",
    content: `Конструктивно клиновой замок для опалубки состоит из губок и клина. Фиксация замка обеспечивается путем вертикального механического воздействия молотком на клин, который, в свою очередь, сжимает губки замка в канале опалубочного профиля, плотно стягивая и выравнивая щиты между собой.`,
    right: false,
    image: acc3_2,
  },
  {
    title: "УНИВЕРСАЛЬНЫЕ ЗАМКИ",
    content: `Замок универсальный для опалубки (оцинкованный, винтовой)

Применяется для выравнивания и закрепления опалубочной конструкции при возведении колонн, стен и фундаментов. Используется в работе с опалубками из разных материалов. Механизм замка состоит из неподвижной части, регулируемой скобы, винта и стяжной гайки.`,
    right: true,
    image: acc3_3,
  },
  {
    title: "ЧИРОЗЫ",
    content: `Чироз – соединительный элемент, используемый для стяжки опалубки в отверстия предусмотренные в щитах, вставляется арматура и с внешней стороны скрепляется.

Производство: Турция`,
    right: false,
    image: acc3_4,
  },
  {
    title: "КЛЮЧ ДЛЯ ЧИРОЗЫ",
    content: `Прижимной ключ для пружинного зажима чироз – инструмент для натяжения арматуры при креплении щитов опалубки с помощью пружинного зажима чироз, изготовлен из специальной стали. Использование этих инструментов позволяет легко и экономично изготовить опалубку для монолитных стен, поясов, ленточных фундаментов и колонн.`,
    right: true,
    image: acc3_5,
  },
  {
    title: "ТРИНОГИ",
    content: `высота- 800 мм

высота треноги - 730 мм

материал - сталь

толщина - 2.5 мм.

покрытие - порошковое

вес - 5 кг.

Тренога - деталь опалубки, обеспечивающая устойчивость опалубочной конструкции перекрытий позволяющее надежно зафиксировать её в вертикальном положении при осуществлении сборки.`,
    right: true,
    image: acc3_6,
  },
];

const acc10 = [
  {
    title: "АРМАТУРА КАТАНКА",
    content: `Арматура строительная по ГОСТ 5781-82 представляет собой круглые стержни с поверхностью, имеющей либо специальное рифление, либо гладкая, но оба вида с использованием двух продольных ребер жесткости на поверхности вдоль оси арматуры для улучшения сцепных свойств с бетоном.

Производство Казахстан и Россия

Диаметр от 8, 10, 12, 14, 16, 18 и выше.

Арматура заводского производства с соблюдением всех технологических процессов для производства высококачественной арматуры.`,
    right: true,
    image: acc10_1,
  },
  {
    title: "ПРОФИЛЛЬНЫЕ ТРУБЫ",
    content: `Изготавливается профильная труба посредством горячего или холодного деформирования электросварной прямошовной круглой трубы. То есть сначала из заготовки (штрипса) сгибают и сваривают круглую трубу расчетного сечения, затем круглая труба поступает в формовку, где специальными валками ей придается нужное сечение. После этого все трубы согласно ГОСТу проходят неразрушающий контроль сварного шва и дополнительно обрабатываются термически для снятия внутренних механических напряжений.



Сечения производимых в России профильных труб: от 10×10 мм до 500x400 мм. Толщина стенки варьируется от 1 до 22 мм. Длина труб от 6,0 до 18,0 м.Профильные трубы используются главным образом для постройки металлоконструкций, монтажа каркасов зданий, различного вида опор, мачт, сложных перекрытий, пролётов и так далее. То есть конструкций, несущих серьезные механические и вибрационные нагрузки. Хотя встречаются и более простые применения — например, в качестве столбов для ограждений.`,
    right: false,
    image: acc10_2,
  },
  {
    title: "КРУГЛЫЕ ТРУБЫ",
    content: ``,
    right: true,
    image: acc10_3,
  },
  {
    title: "ШВЕЛЛЕР",
    content: ``,
    right: false,
    image: acc10_4,
  },
//   {
//     title: "УГЛЫ",
//     content: `Угол опалубки является элементом крупнощитовой опалубки, служащим для формирования внутреннего угла замкнутого контура опалубки лифтовой шахты и бетонирования угловых сопряженных стен.

// Использование распалубочного угла опалубки позволяет производить монтаж/демонтаж внутреннего ядра лифтовой шахты без разбора контура опалубки и обеспечивает перемещение системы подъемным устройством, как единого целого, в один прием.`,
//     right: true,
//     image: acc10_5,
//   },
];
